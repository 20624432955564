html, body {
    margin: 0;
    height: 100%;
    user-select: none;
    text-rendering: optimizeSpeed;
}
.root {
    height: 100%;
}

